import React, { createContext, useState } from 'react'
import { useQuery, useMutation, UseMutationResult, UseQueryResult } from 'react-query'
import { ClientAccountResponse, endpoints } from '@api'
import { useToast, useDisclosure } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { copyToClipboard } from '@utilities'
import moment from 'moment'
import DeleteConfirmationDialog from '@handlers/sessions/components/TreatmentPlan/components/DeleteConfirmationDialog'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { StoreModel } from 'src/store/types'

interface Diagnosis {
  id: string
  code: string
  name: string
}

interface FocusOfTreatment {
  id: string
  name: string
}

export type OptionType = {
  value: string
  label: string
}

interface TreatmentPlanContextType {
  treatmentPlanQuery: UseQueryResult<any, unknown>
  generateMutation: UseMutationResult<void, unknown, void, unknown>
  uploadMutation: UseMutationResult<void, unknown, File, unknown>
  deleteMutation: UseMutationResult<void, unknown, any, unknown>
  acceptMutation: UseMutationResult<void, unknown, any, unknown>
  updateMutation: UseMutationResult<void, unknown, any, unknown>
  regenerateMutation: UseMutationResult<void, unknown, any, unknown>
  trackEventMutation: UseMutationResult<void, unknown, any, unknown>
  canGenerateQuery: UseQueryResult<{ canGenerate: boolean }, unknown>
  diagnosisQuery: UseQueryResult<{ value: string, label: string }[], unknown>
  focusOfTreatmentQuery: UseQueryResult<{ value: string, label: string }[], unknown>
  progressNoteSettingsQuery: UseQueryResult<{ transcriptsEnabled: boolean }, unknown>
  transcriptsEnabled: boolean
  initialSelectedDiagnoses: (OptionType | undefined)[]
  initialSelectedFocuses: (OptionType | undefined)[]
  isUnaccepted: boolean
  treatmentPlan: any
  diagnosisOptions: OptionType[]
  focusOptions: OptionType[]
  client: ClientAccountResponse | undefined
  onCopyAll: () => void
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  diagnosisSuggestions: OptionType[]
  focusSuggestions: OptionType[]
  openDeleteConfirmationModal: () => void
  closeDeleteConfirmationModal: () => void
  diagnosisSuggestionsEnabled: boolean
}

export const TreatmentPlanContext = createContext<TreatmentPlanContextType>({} as TreatmentPlanContextType)

export const TreatmentPlanProvider: React.FC<{ children: React.ReactNode, client?: ClientAccountResponse, toastsEnabled?: boolean }> = ({ client, children, toastsEnabled = true }) => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const [isEditing, setIsEditing] = useState(false)
  const organizationId = user?.clinic?.organization?.id
  const toast = useToast()
  const { 
    isOpen: isDeleteConfirmationOpen, 
    onOpen: openDeleteConfirmationModal, 
    onClose: closeDeleteConfirmationModal 
  } = useDisclosure()
  const clientId = client?.id

  const treatmentPlanQuery = useQuery(
    [endpoints.getClientTreatmentPlan.getCacheId(), clientId],
    () => endpoints.getClientTreatmentPlan.request({ clientId }),
    {
      refetchInterval: (data: any) => data?.treatmentPlan?.isLoading ? 5000 : false,
      enabled: !!clientId
    }
  )

  const generateMutation = useMutation(async () => {
    if (!clientId) return
    await endpoints.createTreatmentPlan.request({ clientId })
    await treatmentPlanQuery.refetch()
  })

  const uploadMutation = useMutation(async (file: File) => {
    if (!clientId) return
    const { url, uploadId }: any = await endpoints.createTreatmentPlanPresignedUrl.request({
      clientId,
      contentType: file.type
    })

    const uploadResponse = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': file.type }
    })

    if (!uploadResponse.ok) throw new Error('Failed to upload file to S3')

    await endpoints.createTreatmentPlan.request({ clientId, uploadId })
    await treatmentPlanQuery.refetch()
  })

  const deleteMutation = useMutation(
    async () => {
      if (!clientId) return
      const treatmentPlanId = treatmentPlanQuery.data?.treatmentPlan?.id
      await endpoints.deleteTreatmentPlan.request({ clientId, treatmentPlanId })
      await Promise.all([treatmentPlanQuery.refetch(), canGenerateQuery.refetch()])
    }, {
    onError: () => {
      toast({
        title: 'An error occurred while deleting the treatment plan. Please try again.',
        status: 'error'
      })
    },
    onSuccess: () => {
      toast({
        title: 'Treatment plan deleted',
        status: 'success'
      })
      closeDeleteConfirmationModal()
    }
  })

  const trackEventMutation = useMutation(async (data: any) => {
    if (!clientId) return
    await endpoints.trackTxPlanEvent.request({ clientId, treatmentPlanId: treatmentPlanQuery.data?.treatmentPlan?.id, data })
  })

  const acceptMutation = useMutation(async (data: any) => {
    if (!clientId) return
    await endpoints.acceptTreatmentPlan.request({ clientId, treatmentPlanId: treatmentPlanQuery.data?.treatmentPlan?.id, data })
    await treatmentPlanQuery.refetch()
  }, {
    onError: () => {
      toast({
        title: 'An error occurred while accepting the treatment plan. Please try again.',
        status: 'error'
      })
    },
    onSuccess: () => {
      setIsEditing(false)
      toast({
        title: 'Treatment plan accepted',
        status: 'success'
      })
    }
  })

  const updateMutation = useMutation(async (data: any) => {
    if (!clientId) return
    await endpoints.updateTxPlan.request({ clientId, treatmentPlanId: treatmentPlanQuery.data?.treatmentPlan?.id, data })
    await treatmentPlanQuery.refetch()
  }, {
    onError: () => {
      if (toastsEnabled) {
        toast({
          title: 'An error occurred while updating the treatment plan. Please try again.',
          status: 'error'
        })
      }
    },
    onSuccess: () => {
      setIsEditing(false)
      if (toastsEnabled) {
        toast({
          title: 'Treatment plan updated',
          status: 'success'
        })
      }
    }
  })

  const regenerateMutation = useMutation(async (data: any) => {
    if (!clientId) return
    await endpoints.regenerateTxPlan.request({ clientId, treatmentPlanId: treatmentPlanQuery.data?.treatmentPlan?.id, data })
    await treatmentPlanQuery.refetch()
  }, {
    onError: () => {
      toast({
        title: 'An error occurred while regenerating the treatment plan. Please try again.',
        status: 'error'
      })
    },
    onSuccess: () => {
      setIsEditing(false)
      toast({
        title: 'Treatment plan regenerated',
        status: 'success'
      })
    }
  })

  const canGenerateQuery = useQuery(
    [endpoints.canGenerateTxPlan.getCacheId(), clientId],
    () => endpoints.canGenerateTxPlan.request({ clientId }) as Promise<{ canGenerate: boolean }>,
    {
      enabled: !!clientId
    }
  )

  const progressNoteSettingsQuery = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId }) as Promise<{ transcriptsEnabled: boolean }>,
    {
      enabled: !!organizationId
    }
  )

  const diagnosisQuery = useQuery<Diagnosis[], unknown, OptionType[]>(
    [endpoints.getDiagnoses.getCacheId()],
    () => endpoints.getDiagnoses.request({}) as Promise<Diagnosis[]>,
    {
      select: data => data.map(diagnosis => ({
        value: diagnosis.id,
        label: `${diagnosis.code} - ${diagnosis.name}`
      })),
      initialData: []
    }
  )

  const focusOfTreatmentQuery = useQuery<FocusOfTreatment[], unknown, OptionType[]>(
    [endpoints.getFocusOfTreatments.getCacheId()],
    () =>
      endpoints.getFocusOfTreatments.request({
        isTxPlanEnabled: true
      }) as Promise<FocusOfTreatment[]>,
    {
      select: data => data.map(focusOfTreatment => ({
        value: focusOfTreatment.id,
        label: focusOfTreatment.name
      })),
      initialData: []
    }
  )
  
  const treatmentPlan = treatmentPlanQuery?.data?.treatmentPlan
  const diagnosisOptions = diagnosisQuery?.data || []
  const focusOptions = focusOfTreatmentQuery?.data || []
  
  const initialSelectedDiagnoses: (OptionType | undefined)[] = treatmentPlan?.diagnosisIds
    ?.map((id: string) =>
      diagnosisOptions.find((option: OptionType) => option.value === id)
    )
    ?.filter((diagnosis: OptionType) => diagnosis) || []

  const initialSelectedFocuses: (OptionType | undefined)[] = treatmentPlan?.focusOfTreatmentIds
    ?.map((id: string) =>
      focusOptions.find((option: OptionType) => option.value === id)
    )
    ?.filter((focus: OptionType) => focus) || []
  
  if (initialSelectedDiagnoses.length === 0) initialSelectedDiagnoses.push(undefined)
  if (initialSelectedFocuses.length === 0) initialSelectedFocuses.push(undefined)

  const transcriptsEnabled = !!(progressNoteSettingsQuery.data?.transcriptsEnabled)
  const isUnaccepted = (treatmentPlan && !treatmentPlan?.isLoading) && !treatmentPlan.acceptedAt

  const focusSuggestions: OptionType[] =
    treatmentPlan?.extractedData?.focus_of_treatment
      ?.map((id: string) =>
        focusOptions?.find((option: OptionType) => option.value === id)
      )
      .filter((focus: OptionType) => focus) || []

  const diagnosisSuggestions: OptionType[] =
    treatmentPlan?.extractedData?.diagnoses
      ?.map((id: string) =>
        diagnosisOptions?.find((option: OptionType) => option.value === id)
      )
      .filter((diagnosis: OptionType) => diagnosis) || []

  const onCopyAll = () => {
    if (!client) return

    const values = []

    values.push(`Name: ${client.first_name} ${client.last_name}`)
    values.push(`DOB: ${moment(client.date_of_birth).format('M/D/YYYY')}`)

    if (initialSelectedDiagnoses.filter(d => d).length) {
      const diagnoses = initialSelectedDiagnoses
        .filter(diagnosis => diagnosis)
        .map(diagnosis => diagnosis?.label)
        .join(', ')

      values.push(`Diagnosis: ${diagnoses}`)
    } else {
      values.push('Diagnosis: N/A')
    }

    if (initialSelectedFocuses.filter(f => f).length) {
      const focuses = initialSelectedFocuses
        .filter(focus => focus)
        .map(focus => focus?.label)
        .join(', ')
      values.push(`Focus of Treatment: ${focuses}`)
    } else {
      values.push('Focus of Treatment: N/A')
    }

    values.push(`Treatment Plan: ${treatmentPlan?.rawText || 'N/A'}`)

    const value = values.join('\n\n')

    trackEventMutation.mutate({
      eventType: 'copied_treatment_plan',
      payload: {
        copiedText: value
      }
    })

    copyToClipboard(value)
    
    toast({
      title: 'Treatment plan copied to clipboard',
      status: 'success'
    })
  }

  const diagnosisSuggestionsEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.DIAGNOSIS_SUGGESTIONS
  )

  return (
    <TreatmentPlanContext.Provider
      value={{
        treatmentPlanQuery,
        generateMutation,
        uploadMutation,
        deleteMutation,
        acceptMutation,
        updateMutation,
        regenerateMutation,
        trackEventMutation,
        canGenerateQuery,
        diagnosisQuery,
        focusOfTreatmentQuery,
        progressNoteSettingsQuery,
        transcriptsEnabled,
        initialSelectedDiagnoses,
        initialSelectedFocuses,
        treatmentPlan,
        diagnosisOptions,
        focusOptions,
        client,
        isUnaccepted,
        onCopyAll,
        isEditing,
        setIsEditing,
        diagnosisSuggestions,
        focusSuggestions,
        openDeleteConfirmationModal,
        closeDeleteConfirmationModal,
        diagnosisSuggestionsEnabled
      }}
    >
      {children}
      <DeleteConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationModal}
        onConfirm={closeDeleteConfirmationModal}
      />
    </TreatmentPlanContext.Provider>
  )
}