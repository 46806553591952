import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'
import { useExperienceManager, useAudioCapture } from '@hooks'
import { times } from 'lodash'

export const AudioLevelV2 = () => {
    const { audioLevel } = useStoreState((state: StoreModel) => state.volume)
    const { isWidget } = useExperienceManager()
    const { isMuted } = useAudioCapture()
    const numBars = isWidget ? 10 : 15
  
    return (
      <Flex mt="16px !important" data-testid="audio-level-v2">
        {times(numBars, i => (
          <Box
            key={i}
            h="16px"
            w="6px"
            borderRadius="4px"
            bg={
              audioLevel >= (i + 1) * (1 / numBars) && !isMuted
                ? '#68E19F'
                : '#CBCCCD'
            }
            border={"1px solid #C3C3C9"}
            mr="6px"
          />
        ))}
      </Flex>
    )
  }