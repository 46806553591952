import { Box, CloseButton, HStack, IconButton, Tooltip, Text, Icon } from '@chakra-ui/react'
import { EndSession, Mic, MicOff } from '@components/icons'
import { SettingsIcon } from '@blueprinthq/joy'
import { trackEvent } from '@lib/clinician-tracking'
import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { useAudioCapture } from '@hooks'
import { motion } from 'framer-motion'

type SessionControlsFooterProps = {
  isMuted: boolean
  onMuteToggle: () => void
  onSettingsClick: () => void
  onEndSession: () => void
}

export const SessionControlsFooter: React.FC<SessionControlsFooterProps> = ({
  isMuted,
  onMuteToggle,
  onSettingsClick,
  onEndSession,
}) => {
  const { showMicWarning, setShowMicWarning } = useAudioCapture()

  return (
    <Box
      as={motion.div}
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        duration: "0.4",
        ease: "easeOut"
      }}
      data-testid="session-controls"
      display="flex"
      justifyContent="center"
      width="100%"
      gap="8px"
      zIndex="docked"
      flex="1"
      alignItems="flex-end"
      paddingBottom="24px"
    >
      <Tooltip
        data-testid="mic-warning-tooltip"
        isOpen={showMicWarning}
        hasArrow
        pointerEvents="all"
        placement="top"
        border="1px solid #2828280D"
        borderRadius="8px"
        bg="#FFF2F2"
        mx="16px"
        maxWidth="350px"
        label={
          <Box
            bg="#FFF2F2"
            py={2}
            pl={2}
            pr={1}
            display="flex"
            color="#282828"
            borderRadius="4px"
            width="100%"
            data-testid="mic-warning-tooltip-content"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Icon as={WarningIcon} color="red.500" mr="16px" boxSize={6} />
              <Text fontSize="16px">
                Hey, <b>we're not hearing any audio</b> from your microphone. Take a moment to check your settings.
              </Text>
              <CloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMicWarning(false);
                }}
                size="sm"
                ml={1}
                color="#757575"
              />
            </Box>
          </Box>
        }
      >
        <IconButton
          backgroundColor="#282828"
          icon={<SettingsIcon fill="white" />}
          colorScheme="white"
          variant="solid"
          aria-label="Call Settings"
          w="72px"
          h="72px"
          borderRadius="50%"
          onClick={() => {
            trackEvent('Session -> Clicked Settings')
            onSettingsClick()
          }}
          margin="0"
        />
      </Tooltip>
      {isMuted ? (
        <Tooltip label="Unmute" aria-label="Unmute tooltip">
          <IconButton
            backgroundColor="white"
            icon={<MicOff fill="#EB5164" />}
            colorScheme="white"
            variant="solid"
            aria-label="Unmute Audio"
            w="72px"
            h="72px"
            borderRadius="50%"
            onClick={onMuteToggle}
            boxShadow="0px 1px 16px rgba(0, 0, 0, 0.05)"
            _hover={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)'
            }}
            margin="0"
          />
        </Tooltip>
      ) : (
        <Tooltip label="Mute" aria-label="Mute tooltip">
          <IconButton
            backgroundColor="#282828"
            icon={<Mic fill="white" />}
            colorScheme="white"
            variant="solid"
            aria-label="Mute Audio"
            w="72px"
            h="72px"
            borderRadius="50%"
            onClick={onMuteToggle}
            margin="0"
          />
        </Tooltip>
      )}
      <Tooltip label="End Session" aria-label="End session tooltip">
        <IconButton
          backgroundColor="#EB5164"
          icon={<EndSession fill="white" />}
          colorScheme="white"
          variant="solid"
          aria-label="End Call"
          w="72px"
          h="72px"
          borderRadius="50%"
          margin="0"
          onClick={() => {
            trackEvent('Session -> Clicked End Session')
            onEndSession()
          }}
        />
      </Tooltip>
    </Box>
  )
}