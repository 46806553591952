import React, { useEffect, useState } from 'react'
import {
  MeetingProvider,
  MeetingManager
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Loading } from '@components'
import { endpoints, SessionEnrollClientRequest } from '@api'
import {
  getClinicControllerGetClientListQueryKey,
  useSessionControllerGetSessionRecap,
  useSessionControllerPostSession
} from '~/clinician-api'
import { SessionActivityV2 } from './session-activity-v2'
import { AudioCaptureProvider, PanelManagerProvider } from '@context'
import { useExperienceManager, useComponentRenderTracker } from '@hooks'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'
import { DateTime } from 'luxon'
import { useToast } from '@chakra-ui/react'

const logger = new ConsoleLogger('chime', LogLevel.WARN)
const manager = new MeetingManager(logger)

export function SessionActivity({
  isDictation = false,
  flagsmithFlagsLoaded
}: {
  isDictation: boolean
  flagsmithFlagsLoaded: boolean
}) {
  useComponentRenderTracker('Viewed Session Page')

  const {
    sessionId: sessionIdFromParams,
    id: clientIdFromParams
  }: { sessionId: string; id: string } = useParams()

  const queryClient = useQueryClient()

  const [clientId, setClientId] = useState(clientIdFromParams)
  const [sessionId, setSessionId] = useState(sessionIdFromParams)
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false)

  const history = useHistory()
  const toast = useToast()

  const {
    isDocumentationAutomationEnabled,
    isSessionRecordingV2Enabled,
    isExtension,
    isPlusPlanEnabled
  } = useExperienceManager()

  const {
    data: recap,
    isLoading: isLoadingSessionRecap,
    refetch: refetchRecap
  } = useSessionControllerGetSessionRecap(sessionId, {
    query: {
      enabled: !!sessionId
    }
  })

  const { data: client, isLoading: isClientLoading } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), clientId],
    () => endpoints.getClinicianUserAccount.request({ id: clientId }),
    { enabled: !!clientId }
  )

  const { data: waitlist, isLoading: isWaitlistLoading } = useQuery(
    endpoints.getFeatureWaitlist.getCacheId(),
    endpoints.getFeatureWaitlist.request
  )

  const { data: user }: { data: any } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const { mutateAsync: enrollClient, isLoading: isEnrolling } = useMutation(
    endpoints.postSessionEnrollClient.request,
    {
      onError: () => {
        toast({
          title: 'Error creating client',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      },
      onSuccess: () => {
        const clientListQueryKey = getClinicControllerGetClientListQueryKey(
          user!.clinic.id
        )
        queryClient.invalidateQueries({ queryKey: clientListQueryKey })
      }
    }
  )

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const handleClientChange = async (clientId: string) => {
    const sessionDraft = await createSessionDraft({
      data: {
        patientId: clientId,
        isExtension,
        isChime: false,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        isDictation,
        resumeInProgress: true,
        isAssistEnabled: isPlusPlanEnabled
      }
    })

    const newPath = sessionDraft.isDictation 
      ? `/patient/${clientId}/dictate/session/${sessionDraft.id}`
      : `/patient/${clientId}/session/${sessionDraft.id}`

    setClientId(clientId)
    setSessionId(sessionDraft.id)
    history.replace(newPath)
  }

  const handleEnrollClientFromModal = async ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun
  }: SessionEnrollClientRequest) => {
    const { patientId } = await enrollClient({
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth
          ? DateTime.fromISO(dateOfBirth).toFormat('yyyy-MM-dd')
          : null,
        pronoun: CLIENT_PRONOUNS[pronoun as keyof typeof CLIENT_PRONOUNS]
      }
    })
    handleClientChange(patientId)
    setIsAddClientModalOpen(false)

    queryClient.invalidateQueries([endpoints.getClientList.getCacheId()])

    toast({
      status: 'success',
      isClosable: true,
      duration: 3000,
      title: 'Client successfully created'
    })
  }

  useEffect(() => {
    if (recap && recap.completedAt) {
      history.push(`/patient/${clientId}/completed-session/${sessionId}`)
    }
  }, [recap])

  if (
    !flagsmithFlagsLoaded ||
    (!isSessionRecordingV2Enabled &&
    (isEnrolling ||
      (clientId && isClientLoading) ||
      (sessionId && isLoadingSessionRecap) ||
      isWaitlistLoading))
  ) {
    return <Loading />
  }

  const isOnAssistWaitlist = (waitlist as any)?.includes('assist')

  return (
    <MeetingProvider meetingManager={manager}>
      <PanelManagerProvider
        isDictation={isDictation}
        isOnAssistWaitlist={isOnAssistWaitlist}
      >
        <AudioCaptureProvider
          sessionId={sessionId}
          refetchRecap={refetchRecap}
          hasStartedRecording={!!recap?.recordingStartedAt}
          isChime={!!recap?.isChime}
        >
          <SessionActivityV2
            recap={recap}
            refetchRecap={refetchRecap}
            sessionId={sessionId}
            client={client}
            aiNotetakerEnabled={isDocumentationAutomationEnabled}
            isDictation={isDictation}
            onClientChange={handleClientChange}
            handleEnrollClientFromModal={handleEnrollClientFromModal}
            isAddClientModalOpen={isAddClientModalOpen}
            isEnrolling={isEnrolling}
            setIsAddClientModalOpen={setIsAddClientModalOpen}
          />
        </AudioCaptureProvider>
      </PanelManagerProvider>
    </MeetingProvider>
  )
}
