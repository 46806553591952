import React, { useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'

import { usePatientControllerGetCheckInScores } from '~/clinician-api'
import { useTreatmentPlan } from '@hooks'

import { CompletedWorksheetList } from './completed-worksheets-list'
import { useAssistPanelContext } from '../context'

export const RecentWorksheets = () => {
  const { client } = useTreatmentPlan()

  const { data } = usePatientControllerGetCheckInScores(client?.id || '', {
    query: {
      initialData: {
        scores: [],
        versions: []
      },
      enabled: !!client?.id
    }
  })

  const scoresWithCheckIn = useMemo(() => {
    return data?.scores.map(score => ({
      ...score,
      checkIn: data.versions.find(v => v.versionId === score.versionId)
    }))
  }, [data])

  const { setOverlayPanel } = useAssistPanelContext()

  return (
    <Box>
      <Text fontWeight="bold" mb="xsmall">
        Recent Activity
      </Text>
      <CompletedWorksheetList
        scoresWithCheckIn={scoresWithCheckIn?.slice(0, 4)!}
        onClickWorksheet={s =>
          setOverlayPanel('WORKSHEET_RESULT', {
            checkIn: s.checkIn,
            score: s
          })
        }
      />
      {scoresWithCheckIn?.length ? (
        <Button
          variant="link"
          textDecoration="none"
          mt="xsmall"
          onClick={() =>
            setOverlayPanel('ALL_WORKSHEET_RESULTS')
          }
        >
          See all
        </Button>
      ) : null}
    </Box>
  )
}
