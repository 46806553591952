import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Text,
  SlideFade
} from '@chakra-ui/react'
import {
  AccountIcon,
  ArrowDownIcon,
  PersonAddIcon,
  SearchIcon
} from '@blueprinthq/joy'
import { ClientSearchResult, endpoints } from '@api'
import { useQuery } from 'react-query'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'
import { throttle } from 'lodash'
import { SelectClientDropdownItem } from './select-client-dropdown-item'

export interface SelectedClient {
  id: string
  firstName: string
  lastName: string
}

interface SelectClientDropdownProps {
  allClientsList: ClientSearchResult[] | undefined
  setIsAddClientModalOpen: Function
  clinicianId: string
  selectedClient: SelectedClient | undefined
  onChange: (clientId: string) => void
  errorMessage?: string
}

export const SelectClientDropdown = ({
  setIsAddClientModalOpen,
  clinicianId,
  allClientsList,
  selectedClient,
  onChange,
  errorMessage
}: SelectClientDropdownProps) => {
  const [searchInput, setSearchInput] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchInput(value)
  }

  let {
    data: preferredClientList,
    isLoading: isPreferredClientListLoading
  } = useQuery(
    [endpoints.getPreferredClientList.getCacheId()],
    () => endpoints.getPreferredClientList.request({ clinicianId }),
    {
      enabled: !!clinicianId
    }
  )
  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent the InputGroup's onClick from firing
    setIsOpen(!isOpen)
    if (isOpen) {
      inputRef.current?.blur()
    } else {
      inputRef.current?.focus()
    }
  }

  const {
    refetch,
    data: searchResults,
    isLoading: isSearchLoading
  } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      searchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: searchInput!,
        include_discharged: false
      }),
    {
      enabled: searchInput !== null,
      select: data => data.filter(n => !n.is_demo || isInternalDemoOrg)
    }
  )

  const handleClientSelect = (clientId: string) => {
    onChange(clientId)
    setIsOpen(false)
  }

  const onFocus = () => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIsOpen(true)
  }

  const onBlur = () => {
    setIsOpen(false)
    setSearchInput(
      selectedClient
        ? `${selectedClient.firstName} ${selectedClient.lastName}`
        : null
    )
  }

  useEffect(() => {
    setSearchInput(
      selectedClient
        ? `${selectedClient.firstName} ${selectedClient.lastName}`
        : null
    )
  }, [selectedClient])

  useEffect(() => {
    if (searchInput && searchInput.length >= 1) {
      throttledSearch()
    }
  }, [searchInput])

  const throttledSearch = throttle(() => refetch(), 150)

  const hasSearchResults = searchResults && searchResults.length > 0

  const getVisibleItems = () => {
    if (searchInput?.length && searchResults?.length) {
      return searchResults
    }
    if (!searchInput?.length) {
      const lastWeekClients = preferredClientList?.clientsWithSessionLastWeek || []
      const allClients = allClientsList || []
      return [...lastWeekClients, ...allClients]
    }
    return []
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const items = getVisibleItems()

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault()
        setHighlightedIndex(prev =>
          prev < items.length - 1 ? prev + 1 : prev
        )
        break
      case 'ArrowUp':
        e.preventDefault()
        setHighlightedIndex(prev =>
          prev > 0 ? prev - 1 : prev
        )
        break
      case 'Enter':
        e.preventDefault()
        if (highlightedIndex >= 0 && items[highlightedIndex]) {
          handleClientSelect(items[highlightedIndex].id)
        } else if (items.length > 0) {
          // Select first item if none highlighted
          handleClientSelect(items[0].id)
        }
        break
      case 'Escape':
        e.preventDefault()
        setIsOpen(false)
        setSearchInput(
          selectedClient
            ? `${selectedClient.firstName} ${selectedClient.lastName}`
            : null
        )
        inputRef.current?.blur()
        break
    }
  }

  // Reset highlighted index when search changes
  useEffect(() => {
    setHighlightedIndex(-1)
  }, [searchInput])

  return (
    <Box position="relative" width="100%" ref={dropdownRef}>
      <InputGroup
        cursor="pointer"
        color="black"
        onClick={() => setIsOpen(true)}
      >
        {selectedClient && !isOpen ? (
          <InputLeftElement 
            ml="4px"
            onClick={handleIconClick}
            cursor="pointer"
          >
            <AccountIcon color="gray.300" />
          </InputLeftElement>
        ) : (
          <InputLeftElement 
            ml="4px"
            onClick={handleIconClick}
            cursor="pointer"
          >
            <SearchIcon color="gray.300" />
          </InputLeftElement>
        )}
        <Input
          ref={inputRef}
          placeholder="Select or add your client"
          value={searchInput || ''}
          borderRadius="8px"
          bg="white"
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          borderColor={errorMessage ? '#EB5164' : '#e4e5e6'}
          _hover={{
            borderColor: errorMessage ? '#EB5164' : '#e4e5e6'
          }}
          _focus={{
            borderColor: errorMessage ? '#EB5164' : '#2D54E8'
          }}
          _placeholder={{
            color: '#A1A1A1'
          }}
          onKeyDown={handleKeyDown}
          role="combobox"
          aria-expanded={isOpen}
          aria-controls="client-listbox"
          aria-activedescendant={
            highlightedIndex >= 0
              ? `client-option-${getVisibleItems()[highlightedIndex]?.id}`
              : undefined
          }
        />
        <InputRightElement 
          mr="5px" 
          onClick={handleIconClick}
          cursor="pointer"
        >
          <ArrowDownIcon 
            color="gray.300" 
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease'
            }}
          />
        </InputRightElement>
      </InputGroup>
      {errorMessage && (
        <Text
          color="#EB5164"
          fontSize="14px"
          mt="4px"
          ml="4px"
          textAlign="left"
        >
          {errorMessage}
        </Text>
      )}
      {isOpen && (
        <SlideFade
          in={isOpen}
          unmountOnExit
          offsetY="0px"
          style={{ position: 'absolute', width: '100%', zIndex: 1000 }}
          transition={{
            enter: { 
              duration: 0.2,
              ease: "easeOut"
            },
            exit: {
              duration: 0.15,
              ease: "easeIn"
            }
          }}
        >
          <Box
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            mt="2"
            width="100%"
            boxShadow="0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A"
            style={{
              transformOrigin: 'top',
              animation: isOpen ? 'ios-spring 0.2s ease-out' : undefined
            }}
          >
            <style>
              {`
                @keyframes ios-spring {
                  0% {
                    opacity: 0;
                    transform: scale(0.95);
                  }
                  70% {
                    transform: scale(1.02);
                  }
                  100% {
                    opacity: 1;
                    transform: scale(1);
                  }
                }
              `}
            </style>
            <List
              id="client-listbox"
              role="listbox"
              spacing={1}
              maxHeight="300px"
              minHeight="180px"
              overflowY="auto"
              pb="64px"
            >
              {searchInput?.length && !hasSearchResults && (
                <ListItem px="16px" py="16px">
                  <Text fontSize="16px" color="#282828" textAlign="left">
                    No results found
                  </Text>
                </ListItem>
              )}
              {searchInput?.length && hasSearchResults && (
                <>
                  <ListItem px="16px" pt="8px">
                    <Text fontSize="12px" color="gray.600" textAlign="left">
                      Search Results
                    </Text>
                  </ListItem>
                  {searchResults.map((client, index) => (
                    <SelectClientDropdownItem
                      key={`search-result-${client.id}`}
                      firstName={client.first_name}
                      lastName={client.last_name}
                      clientId={client.id}
                      onSelect={handleClientSelect}
                      trackingLabel="Search Results"
                      isDemo={client.is_demo}
                      isHighlighted={highlightedIndex === index}
                      id={`client-option-${client.id}`}
                    />
                  ))}
                </>
              )}
              {!searchInput?.length &&
                preferredClientList &&
                preferredClientList.clientsWithSessionLastWeek?.length > 0 && (
                  <>
                    <ListItem
                      px="16px"
                      pt="8px"
                      borderTop="1px solid"
                      borderColor="#E4E5E6"
                    >
                      <Text fontSize="12px" color="gray.600" textAlign="left">
                        Clients you met with last week
                      </Text>
                    </ListItem>
                    {preferredClientList?.clientsWithSessionLastWeek.map(
                      (client, index) => (
                        <SelectClientDropdownItem
                          key={`last-week-${client.id}`}
                          firstName={client.firstName}
                          lastName={client.lastName}
                          clientId={client.id}
                          onSelect={handleClientSelect}
                          trackingLabel="Session Last Week"
                          isDemo={client.isDemo}
                          isHighlighted={highlightedIndex === index}
                          id={`client-option-${client.id}`}
                        />
                      )
                    )}
                  </>
                )}
              {!searchInput?.length &&
                allClientsList &&
                allClientsList.length > 0 && (
                  <>
                    <ListItem
                      px="16px"
                      pt="8px"
                      borderTop="1px solid"
                      borderColor="#E4E5E6"
                    >
                      <Text fontSize="12px" color="gray.600" textAlign="left">
                        All Clients
                      </Text>
                    </ListItem>
                    {allClientsList.map((client, index) => (
                      <SelectClientDropdownItem
                        key={`all-clients-${client.id}`}
                        firstName={client.firstName}
                        lastName={client.lastName}
                        clientId={client.id}
                        onSelect={handleClientSelect}
                        trackingLabel="All Clients"
                        isDemo={client.isDemo}
                        isHighlighted={highlightedIndex === index}
                        id={`client-option-${client.id}`}
                      />
                    ))}
                  </>
                )}
            </List>
            <Box
              position="absolute"
              bottom="0"
              bg="white"
              color="black"
              px="16px"
              py="8px"
              _hover={{ bg: 'gray.100', cursor: 'pointer' }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              height="56px"
              width="100%"
              onMouseDown={() => setIsAddClientModalOpen(true)}
              boxShadow="0px -2px 4px -1px #00000005, 0px -4px 6px -1px #0000000A"
            >
              <PersonAddIcon fill="#2D54E8" marginTop="6px" marginRight="8px" />
              <Text color="#2D54E8" fontSize="16px">
                Add Client
              </Text>
            </Box>
          </Box>
        </SlideFade>
      )}
    </Box>
  )
}
