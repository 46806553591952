import React from 'react'
import { Stack, Box, Text, Button } from '@chakra-ui/react'
import { useAssistPanelContext } from '../context'
import { Suggestions } from './suggestions'
import { RecentWorksheets } from './recent-worksheets'
import { AssignedWorksheets } from './assigned'
import { useTreatmentPlan } from '@hooks'

export const Worksheets = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const handleAssignWorksheet = (worksheet: any) => {
    if (!!client?.enrollment_timestamp) {
      setOverlayPanel('ASSIGN_WORKSHEETS', {
        worksheet: {
          checkInId: worksheet.id
        }
      })
    } else {
      setOverlayPanel('ENROLL_CLIENT', {
        checkIn: {
          checkInId: worksheet.id
        }
      })
    }
  }

  const handleBrowseLibraryClick = () => {
    setOverlayPanel('WORKSHEET_LIBRARY', {
      onAssign: handleAssignWorksheet
    })
  }

  return (
    <Stack
      px="24px"
      spacing="0"
      flex="1"
      py="24px"
      gap="24px"
      h="calc(100vh - 208px)"
      overflowY="scroll"
    >
      <RecentWorksheets />
      <Suggestions />
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="xsmall"
        >
          <Text fontWeight="bold">Assigned</Text>
          <Button
            variant="link"
            textDecoration="none"
            onClick={handleBrowseLibraryClick}
          >
            Browse library
          </Button>
        </Box>
        <AssignedWorksheets patient={client} />
      </Box>
    </Stack>
  )
}
